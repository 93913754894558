import type { SubmitButtonTranslationObj } from "../../translation-keys/submit-button-text-keys.js";

const DownloadSubmitButtonText ="다운로드 받기";
const SubmitButtonMapText: SubmitButtonTranslationObj = {
  "document-Submit-Button": DownloadSubmitButtonText,
  "document-landing-page-Submit-Button": DownloadSubmitButtonText,
  "post-event-assets-Submit-Button": "Get Instant Access",
  "ppc-Submit-Button": DownloadSubmitButtonText,
  "ppc-contact-Submit-Button": "Get Some Answers",
  "case-study-Submit-Button": "Download Free Case Study",
  "video-Submit-Button": DownloadSubmitButtonText,
  "video-landing-page-Submit-Button": "Submit and Play",
  "contact-Submit-Button": "Get Answers",
  "qx-roi-Submit-Button": "Schedule Your ROI Assessment Today",
  "mx-roi-Submit-Button": "Schedule Your ROI Assessment Today",
  "spark-Submit-Button": "Contact a Spark Expert",
  "pricing-Submit-Button": "Get Pricing",
  "subscription-Submit-Button": "Subscribe",
  "partner-Submit-Button": "Submit",
  "demo-request-Submit-Button": "Schedule Your Demo",
  "tradeshow-giveaway-Submit-Button": "Submit",
  "event-registration-Submit-Button": "Register Now",
  "subprocessor-update-notification-Submit-Button": "Submit",
  "digital-maturity-assessment-Submit-Button": "Access My Full Report",
  "qx-demo-video-Submit-Button": "Watch the Demo!"
}; // need info/translations for all of these

export default SubmitButtonMapText;
